import axios from "axios";
import * as actions from "../api";

const api =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type !== actions.apiCallBegan.type) return next(action);

        const { url, method, headers, data, onStart, onSuccess, onError } =
            action.payload;

        if (onStart) dispatch({ type: onStart });

        next(action);

        try {
            const response = await axios.request({
                baseURL: "https://stage-app.kovertly.com/v1",
                url,
                method,
                headers,
                data,
            });
            // General
            dispatch(actions.apiCallSuccess(response.data));
            // Specific

            if (onSuccess) {
                if (Array.isArray(onSuccess))
                    onSuccess.forEach((handler) =>
                        dispatch({ type: handler, payload: response.data })
                    );
                else dispatch({ type: onSuccess, payload: response.data });
            }
        } catch (error) {
            // General
            dispatch(actions.apiCallFailed(error.message));
            // Specific
            if (onError)
                dispatch({
                    type: onError,
                    payload: {
                        data: error.response.data,
                        status: error.response.status,
                    },
                });
        }
    };

export default api;
