export const CASH = { id: "CASH", name: "Cash", requestCallback: true };
export const ACTION = { id: "ACTION", name: "Action", requestCallback: true };
export const DISCARD = {
    id: "DISCARD",
    name: "Discard",
    requestCallback: true,
};
export const REARRANGEMENT_PROPERTY = {
    id: "REARRANGEMENT",
    name: "Rearrange",
    requestCallback: true,
};
export const REARRANGEMENT_CASH = {
    id: "REARRANGEMENT",
    name: "Bring To Top",
    requestCallback: true,
};

export const SELECT_SET = {
    id: "SET",
    name: "Select Set",
    requestCallback: true,
};
export const SELECT_COLOR = {
    id: "COLOR",
    name: "Create New Set",
    requestCallback: true,
};
