import { useSelector, useDispatch } from "react-redux";
import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Card from "../Card/Card.js";
import { dialogUpdated } from "../../store/ui";
import { WebSocketContext } from "../../WebSocket.js";

import "./Prompt.css";

const Prompt = () => {
    const currPlayerId = useSelector(
        (state) => state.entities.game.public.turn.turn
    );
    const viewerId = useSelector(
        (state) => state.entities.game.private.player_id
    );
    const { open, collapsable, title, body, options, cardId, setColor } =
        useSelector((state) => state.ui.dialog);

    const ws = useContext(WebSocketContext);

    const playCard = (selected) => {
        ws.playCard({ selected });
    };

    const ackAction = (selected) => {
        ws.ackAction({ selected });
    };

    const terminateAction = () => {
        ws.terminateAction();
    };

    const dispatch = useDispatch();

    const handleClose = async (selected, requestCallback) => {
        await dispatch({
            type: dialogUpdated.type,
            payload: {
                open: false,
                selected: selected,
            },
        });
        if (!selected) terminateAction();
        if (requestCallback)
            viewerId === currPlayerId
                ? playCard(selected)
                : ackAction(selected);
    };

    return (
        <Dialog
            className="prompt"
            open={open}
            onClose={() =>
                options && options.length && !collapsable
                    ? false
                    : handleClose()
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title ? (
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            ) : null}
            <DialogContent>
                <Card cardId={cardId} setColor={setColor} zoom="2_0" />
            </DialogContent>
            {options ? (
                <DialogActions>
                    <DialogContentText id="alert-dialog-description">
                        {body}
                    </DialogContentText>
                    <div className="action-btn-container">
                        {options.map((option) => (
                            <div
                                className="dialog-btn"
                                key={option.id}
                                onClick={() =>
                                    handleClose(
                                        option.id,
                                        option.requestCallback
                                    )
                                }
                            >
                                {option.name}
                            </div>
                        ))}
                    </div>
                </DialogActions>
            ) : null}
        </Dialog>
    );
};

export default Prompt;
