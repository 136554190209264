import React from "react";

import "./CheckBox.css";

const CheckBox = ({ props }) => (
    <label className="form-options hidden">
        {props.description}
        <input name={props.name} type="checkbox" checked={props.checked} />
        <span className="checkmark" />
    </label>
);

export default CheckBox;
