import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "game",
    initialState: {},
    reducers: {
        // action => action handler
        privateDataFetched: (game, action) => {
            game.private = action.payload;
        },
        publicDataFetched: (game, action) => {
            game.public = action.payload;
        },
        actionReceived: (game, action) => {
            game.action = action.payload;
        },
    },
});

export const { privateDataFetched, publicDataFetched, actionReceived } =
    slice.actions;

export default slice.reducer;
