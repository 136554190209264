// import { Fragment, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
// import { Button } from "@material-ui/core";

import { WebSocketContext } from "../../WebSocket.js";

import "../GameContainer/GameContainer.css";
import "../Card/Card.css";

const Card = ({
    cardId,
    type,
    setColor,
    zoom,
    cash,
    expanded,
    onCardClick,
    selected,
}) => {
    const allCards = useSelector((state) => state.entities.cards.all);
    const currPlayerId = useSelector(
        (state) => state.entities.game.public.turn.turn
    );
    const viewerId = useSelector(
        (state) => state.entities.game.private.player_id
    );
    const _selected = useSelector((state) => state.ui.dialog.selected);
    const ws = useContext(WebSocketContext);

    const playCard = () => {
        ws.playCard({ cardId });
    };

    const ackAction = () => {
        ws.ackAction(null, cardId, null, _selected);
    };

    return (
        <div
            className={
                "child-" +
                type +
                "-stack" +
                (cash ? " child-cash-stack" : "") +
                (expanded
                    ? " enlarged-" +
                      type +
                      "-stack-card" +
                      (cash ? " enlarged-cash-card" : "")
                    : "")
            }
        >
            <img
                onClick={() =>
                    onCardClick
                        ? onCardClick(cardId)
                        : viewerId === currPlayerId
                        ? playCard()
                        : ackAction()
                }
                alt={cardId}
                className={
                    "card-img card" +
                    (expanded ? " enlarged-card-image" : "") +
                    (selected ? " card-selected" : "") +
                    (zoom === "2_0" ? " card-zoomed" : "") +
                    (setColor &&
                    allCards[cardId].colors.length <= 2 &&
                    setColor !== allCards[cardId].colors[0]
                        ? " card-inverted"
                        : "")
                }
                src={
                    "/images/cards/" +
                    (cardId && allCards[cardId].code
                        ? allCards[cardId].code
                        : "M00") +
                    ".svg"
                }
            />
        </div>
    );
};

export default Card;
