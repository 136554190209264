// import Button from "@mui/material/Button";

import "../GameContainer/GameContainer.css";
import "./TitleBar.css";

const TitleBar = ({ isWinner, title, numNotifs, ctrlBtns, isTurn }) => {
    return (
        <div className={"title-bar" + (isTurn ? " turn-highlighter" : "")}>
            {numNotifs ? (
                <div className="title-bar-num-notifs">
                    {"Chances left: " + numNotifs}
                </div>
            ) : null}
            <div className="title-bar-title drag">{title}</div>
            {ctrlBtns.map((btn) => (
                <div
                    key={btn.label}
                    onClick={btn.clickHandler}
                    className={
                        "title-bar-btn title-bar-ctrl-btns" +
                        (btn.visible ? "" : " hidden")
                    }
                >
                    {btn.label}
                </div>
            ))}
        </div>
    );
};

export default TitleBar;
