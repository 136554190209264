import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "cards",
    initialState: {
        all: {},
        selected: [],
    },
    reducers: {
        // action => action handler
        cardsFetched: (cards, action) => {
            cards.all = action.payload;
        },
        cardsSelected: (cards, action) => {
            cards.selected = action.payload;
        },
    },
});

export const { cardsFetched, cardsSelected } = slice.actions;

export default slice.reducer;
