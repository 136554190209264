import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "peers",
    initialState: {},
    reducers: {
        // action => action handler
        peersFetched: (peers, action) => {
            Object.assign(peers, action.payload);
        },

        peersRemoved: (peers, action) => {
            peers.length = 0;
        },
    },
});

export const { peersFetched, peersRemoved } = slice.actions;

export default slice.reducer;
