import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { authenticated } from "./auth";

import { ABSENT, LOADING, PRESENT, EXPIRED } from "../constants/status";
// import { loadingShown } from "./ui";

const slice = createSlice({
    name: "room",
    initialState: {
        status: ABSENT,
    },
    reducers: {
        // action => action handler
        roomHosted: (room, action) => {
            console.log("Room hosted: ", action.payload);
            room.id = action.payload.data.room_id;
            localStorage.setItem("auth", action.payload.data.token);
        },

        roomHostFailed: (room, action) => {
            console.log("Room host failed: ", action.payload);
            // log error
            // show a toast
        },

        roomJoined: (room, action) => {
            //room.id = action.payload.data.room_id;
            //room.links = action.payload.data.links;
        },

        roomJoinFailed: (room, action) => {
            console.log(action.payload);
            // log error
            // show a toast
        },

        roomLeft: (room, action) => {
            room = Object.assign({ status: ABSENT });
            // do the necessary
        },

        roomLeaveFailed: (room, action) => {
            console.log(action.payload);
            // log error
            // show a toast
        },

        roomDetailsRequested: (room, action) => {
            room.status = LOADING;
        },

        roomDetailsFetched: (room, action) => {
            room = Object.assign(room, {
                status: PRESENT,
                ...action.payload.data,
            });
        },

        roomDetailsFetchFailed: (room, action) => {
            console.log(action.payload);
            room.status = EXPIRED;
        },
    },
});

export const createRoom = (payload) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/room/host/",
            method: "POST",
            data: payload,
            onSuccess: [roomHosted.type, authenticated.type],
            onError: roomHostFailed.type,
        })
    );
};

export const joinRoom =
    ({ room, ...payload }) =>
    (dispatch, getState) => {
        return dispatch(
            apiCallBegan({
                url: `/room/join/${room}/`,
                method: "POST",
                data: payload,
                onSuccess: [roomJoined.type, authenticated.type],
                onError: roomJoinFailed.type,
            })
        );
    };

export const fetchRoomDetails = () => async (dispatch, getState) => {
    await dispatch({ type: roomDetailsRequested.type });

    await dispatch(
        apiCallBegan({
            url: "/room/detail/?tenet=monopoly",
            method: "GET",
            headers: { Authorization: getState().auth.token },
            onSuccess: roomDetailsFetched.type,
            onError: roomDetailsFetchFailed.type,
        })
    );
};

export const leaveRoom = () => (dispatch, getState) => {
    localStorage.removeItem("auth");
    localStorage.removeItem("gameStarted");
};

export const {
    roomHosted,
    roomHostFailed,
    roomJoined,
    roomJoinFailed,
    roomDetailsRequested,
    roomDetailsFetched,
    roomDetailsFetchFailed,
    roomLeft,
    roomLeaveFailed,
} = slice.actions;

export default slice.reducer;
