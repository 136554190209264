import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import WebSocketProvider from "./WebSocket.js";
import store from "./store/store.js";
import HomeContainer from "./components/HomeContainer/HomeContainer.js";
import GameContainer from "./components/GameContainer/GameContainer.js";

import "./App.css";

const App = () => {
    const authToken = localStorage.getItem("auth");
    const gameStarted = localStorage.getItem("gameStarted");
    const Component = () =>
        authToken && gameStarted ? <GameContainer /> : <HomeContainer />;

    return (
        <Provider store={store}>
            <WebSocketProvider authToken={authToken}>
                <Router>
                    <Routes>
                        <Route path="/" exact element={<Component />} />
                        <Route path="/:roomId" exact element={<Component />} />
                        <Route path="/play" exact element={<Component />} />
                    </Routes>
                </Router>
            </WebSocketProvider>
        </Provider>
    );
};

export default App;
