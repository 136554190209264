import React, { createContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { cardsFetched, cardsSelected } from "./store/cards.js";
import {
    actionReceived,
    privateDataFetched,
    publicDataFetched,
} from "./store/game.js";
import {
    cardPickerUpdated,
    setPickerUpdated,
    dialogUpdated,
} from "./store/ui.js";
import { peersFetched } from "./store/peers.js";
import {
    getAJNCards,
    getHandCards,
    getPropertySets,
    getBankCards,
    isPropertyCard,
    isMoneyCard,
} from "./utils/card.js";
import {
    CASH,
    ACTION,
    DISCARD,
    REARRANGEMENT_PROPERTY,
    REARRANGEMENT_CASH,
} from "./constants/options.js";
import { SELECT_SET, SELECT_COLOR } from "./constants/options.js";

export const WebSocketContext = createContext();

const WebSocketProvider = (props) => {
    const socketRef = useRef(null);
    const actionState = useRef(null);
    const reactionState = useRef(null);
    const allCards = useSelector((state) => state.entities.cards.all);
    const selectedCards = useSelector((state) => state.entities.cards.selected);
    const game = useSelector((state) => state.entities.game.public);
    const publicData = useSelector((state) => state.entities.game.public);
    const privateData = useSelector((state) => state.entities.game.private);
    const action = useSelector((state) => state.entities.game.action);

    const dispatch = useDispatch();

    const _playCard = async () => {
        const { cardId, playAs, payload } = actionState.current;
        console.log(
            "_playCard>>> cardId:",
            cardId,
            ", playAs:",
            playAs,
            ", payload:",
            payload
        );
        await socketRef.current.emit(
            "action",
            { card_id: cardId, play_as: playAs, payload: payload },
            (response) => {
                toast(response.message);
                console.log(response);
            }
        );
        actionState.current = null;
    };

    const _playCashCard = async (payload) => {
        await _playCard();
    };

    const _rearrangeCard = async (payload) => {
        const { cardId } = actionState.current;
        const { selected } = payload;
        if (!actionState.current.step) actionState.current.step = 1;
        else actionState.current.step += 1;

        switch (actionState.current.step) {
            case 1:
                if (isPropertyCard(allCards[cardId].type)) {
                    await dispatch({
                        type: dialogUpdated.type,
                        payload: {
                            open: true,
                            collapsable: true,
                            cardId: cardId,
                            options: [SELECT_SET, SELECT_COLOR],
                        },
                    });
                } else {
                    await _playCard();
                }
                break;
            case 2:
                switch (selected) {
                    case "SET":
                        await dispatch({
                            type: setPickerUpdated.type,
                            payload: {
                                open: true,
                                options: [
                                    {
                                        id: "DONE",
                                        name: "DONE",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "CANCEL",
                                        name: "CANCEL",
                                    },
                                ],
                            },
                        });
                        break;
                    case "COLOR":
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardId: cardId,
                                options: allCards[cardId].colors.map(
                                    (color) => ({
                                        id: color,
                                        name: color,
                                        requestCallback: true,
                                    })
                                ),
                            },
                        });
                        break;
                    default:
                        console.log(
                            "Something went wrong!",
                            actionState.current
                        );
                }
                break;
            case 3:
                if (selected.startsWith("ps")) {
                    actionState.current = {
                        ...actionState.current,
                        payload: {
                            set: {
                                set_id: selected,
                            },
                        },
                    };
                } else {
                    actionState.current = {
                        ...actionState.current,
                        payload: {
                            set: { color: selected },
                        },
                    };
                }
                await _playCard();
                break;
            default:
                console.log("Something went wrong!", actionState.current);
        }
    };

    const _discardCard = async () => {
        const { cardId, playAs } = actionState.current;
        await socketRef.current.emit(
            "action",
            {
                card_ids: [cardId],
                play_as: playAs,
            },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        actionState.current = null;
    };

    const _playActionCard = async ({ selected, ...others }) => {
        const { cardId, setId, playerId } = actionState.current;
        const cardCode = allCards[cardId].code;

        if (!actionState.current.step) actionState.current.step = 1;
        else actionState.current.step += 1;

        console.log(
            "_playActionCard>>> actionState:",
            actionState.current,
            ", selected:",
            selected,
            ", others:",
            others
        );

        switch (cardCode) {
            /* Basic Property Cards */
            case "PBr":
            case "PDb":
            case "PLg":
            case "PBl":
            case "POr":
            case "PRd":
            case "PDg":
            case "PPk":
            case "PYl":
            case "PLb":
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or skip to create a new set
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: [
                                    {
                                        id: "SET",
                                        name: "Select Set",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "SKIP",
                                        name: "Create New Set",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "SET":
                                await dispatch({
                                    type: setPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        options: [
                                            {
                                                id: "DONE",
                                                name: "DONE",
                                                requestCallback: true,
                                            },
                                            {
                                                id: "CANCEL",
                                                name: "CANCEL",
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                // Since it is final step, send the action to backend.
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 3:
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: {
                                    set_id: selected,
                                },
                            },
                        };
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Property Wild Cards */
            case "PWCXX":
            case "PWCRdYl":
            case "PWCOrPk":
            case "PWCLbBl":
            case "PWCDgBl":
            case "PWCLgBl":
            case "PWCLbBr":
            case "PWCDbDg":
                // Set ID or color
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or select a color
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: [
                                    {
                                        id: "SET",
                                        name: "Select Set",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "COLOR",
                                        name: "Create New Set",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "SET":
                                await dispatch({
                                    type: setPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        options: [
                                            {
                                                id: "DONE",
                                                name: "DONE",
                                                requestCallback: true,
                                            },
                                            {
                                                id: "CANCEL",
                                                name: "CANCEL",
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "COLOR":
                                await dispatch({
                                    type: dialogUpdated.type,
                                    collapsable: true,
                                    payload: {
                                        open: true,
                                        cardCode: cardCode,
                                        cardId: cardId,
                                        setId: setId,
                                        playerId: playerId,
                                        options: allCards[cardId].colors.map(
                                            (color) => ({
                                                id: color,
                                                name: color,
                                                requestCallback: true,
                                            })
                                        ),
                                    },
                                });
                                break;
                            default:
                                console.log(
                                    "Something went wrong!",
                                    actionState.current
                                );
                        }
                        break;
                    case 3:
                        if (selected.startsWith("ps")) {
                            actionState.current = {
                                ...actionState.current,
                                payload: {
                                    set: {
                                        set_id: selected,
                                    },
                                },
                            };
                        } else {
                            actionState.current = {
                                ...actionState.current,
                                payload: {
                                    set: { color: selected },
                                },
                            };
                        }
                        await _playCard();
                        break;
                    default:
                        console.log(
                            "Something went wrong!",
                            actionState.current
                        );
                }
                break;
            /* Action Cards */
            case "ASD" /* Sly Deal */:
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a card to steal.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: playerId,
                                card_id: cardId,
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "AFD" /* Forced Deal */:
                switch (actionState.current.step) {
                    case 1:
                        toast("Select the card you want to swap out.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                swap_card_id: cardId,
                            },
                        };
                        toast("Select the card you want to swap in.");
                        break;
                    case 3:
                        actionState.current.payload = {
                            ...actionState.current.payload,
                            player_id: playerId,
                            card_id: cardId,
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "ADB" /* Deal Breaker */:
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a full set of another player.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: playerId,
                                set: { set_id: setId },
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "ADC" /* Debt Collector */:
                switch (actionState.current.step) {
                    case 1:
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                body: "Select a player to collect debt from.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: selected,
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "AIB" /* Birthday */:
            case "APG" /* Pass Go */:
                await _playCard();
                break;
            /* Rent Card (Two Colors) */
            case "ARTDbDg":
            case "ARTLbBr":
            case "ARTLgBl":
            case "ARTOrPk":
            case "ARTRdYl":
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a property set to collect rent on.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: setId },
                            },
                        };
                        const handCards = Object.values(
                            privateData.private.sets
                        ).filter((set) => set.id.startsWith("hs"))[0].cards;
                        if (
                            handCards.filter((cid) => cid.startsWith("cd_adr"))
                                .length === 0
                        ) {
                            await _playCard();
                            break;
                        }
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: [
                                    { id: "DOUBLE_RENT", name: "Double Rent" },
                                    {
                                        id: "SKIP",
                                        name: "Skip",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 3:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards =
                                    [cardId];
                                const handCards = Object.values(
                                    privateData.private.sets
                                ).filter((set) => set.id.startsWith("hs"))[0]
                                    .cards;
                                if (
                                    handCards.filter((cid) =>
                                        cid.startsWith("cd_adr")
                                    ).length !== 2
                                ) {
                                    await _playCard();
                                    break;
                                }
                                await dispatch({
                                    type: dialogUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: true,
                                        cardCode: cardCode,
                                        cardId: cardId,
                                        setId: setId,
                                        playerId: playerId,
                                        options: [
                                            {
                                                id: "DOUBLE_RENT",
                                                name: "Double Rent",
                                            },
                                            {
                                                id: "SKIP",
                                                name: "Skip",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 4:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards.push(
                                    cardId
                                );
                                await _playCard();
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 5:
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Rent Card (All Colors) */
            case "ARTXX":
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a property set to collect rent on.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: playerId,
                                set: { set_id: setId },
                            },
                        };
                        await dispatch({
                            type: dialogUpdated.type,
                            payload: {
                                open: true,
                                collapsable: true,
                                body: "Select a player to collect rent from.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 3:
                        // Save input from step 2.
                        actionState.current.payload.player_id = selected;

                        const handCards = Object.values(
                            privateData.private.sets
                        ).filter((set) => set.id.startsWith("hs"))[0].cards;
                        if (
                            handCards.filter((cid) => cid.startsWith("cd_adr"))
                                .length === 0
                        ) {
                            await _playCard();
                            break;
                        }
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: [
                                    { id: "DOUBLE_RENT", name: "Double Rent" },
                                    {
                                        id: "SKIP",
                                        name: "Skip",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 4:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards =
                                    [cardId];

                                const handCards = Object.values(
                                    privateData.private.sets
                                ).filter((set) => set.id.startsWith("hs"))[0]
                                    .cards;
                                if (
                                    handCards.filter((cid) =>
                                        cid.startsWith("cd_adr")
                                    ).length !== 2
                                ) {
                                    await _playCard();
                                    break;
                                }
                                await dispatch({
                                    type: dialogUpdated.type,
                                    collapsable: true,
                                    payload: {
                                        open: true,
                                        cardCode: cardCode,
                                        cardId: cardId,
                                        setId: setId,
                                        playerId: playerId,
                                        options: [
                                            {
                                                id: "DOUBLE_RENT",
                                                name: "Double Rent",
                                            },
                                            {
                                                id: "SKIP",
                                                name: "Skip",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 5:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards.push(
                                    cardId
                                );
                                await _playCard();
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "AHT" /* Hotel */:
            case "AHS" /* House */:
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or skip to create a new set
                        toast("Select a full set.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: setId },
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "ADR" /* Double Rent */:
                console.log("Something went wrong!");
                break;
            case "AJN" /* Just Say No */:
                toast("This card cannot initiate an action.");
                actionState.current = null;
                break;
            default:
                toast("This card cannot be played as action card.");
                actionState.current = null;
        }
    };

    const playCard = async (payload) => {
        console.log("playCard>>> payload:", payload);
        if (!actionState.current) {
            actionState.current = payload;
            const { cardId } = actionState.current;
            const handCards = getHandCards(privateData);
            const playerId = privateData.player_id;
            const propertySets = getPropertySets(publicData, playerId);
            const bankCards = getBankCards(privateData);
            const cardType = allCards[cardId].type;
            let options = [];

            if (handCards.includes(cardId)) {
                if (isMoneyCard(cardType)) {
                    options = [CASH, DISCARD];
                } else if (isPropertyCard(cardType)) {
                    options = [ACTION, DISCARD];
                } else {
                    options = [CASH, ACTION, DISCARD];
                }
            } else if (bankCards.includes(cardId)) {
                options = [REARRANGEMENT_CASH];
            } else if (propertySets.find((p) => p.cards.includes(cardId))) {
                actionState.current.setColor = propertySets.find((p) =>
                    p.cards.includes(cardId)
                ).color;
                options = [REARRANGEMENT_PROPERTY];
            }

            await dispatch({
                type: dialogUpdated.type,
                payload: {
                    open: true,
                    collapsable: true,
                    cardId: cardId,
                    setColor: actionState.current.setColor,
                    options: options,
                },
            });
        } else {
            if (!actionState.current.playAs) {
                const { selected } = payload;
                actionState.current.playAs = selected;
            }

            switch (actionState.current.playAs) {
                case "CASH":
                    _playCashCard(payload);
                    break;
                case "REARRANGEMENT":
                    _rearrangeCard(payload);
                    break;
                case "DISCARD":
                    _discardCard(payload);
                    break;
                case "ACTION":
                default:
                    _playActionCard(payload);
                    break;
            }
        }
        console.log("Action state: ", actionState.current);
    };

    const _ackAction = async () => {
        const { card_id, play_as, payload } = reactionState.current;
        await socketRef.current.emit(
            "action",
            { card_id, play_as, payload },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        reactionState.current = null;
        dispatch({
            type: cardsSelected.type,
            payload: [],
        });
        dispatch({
            type: actionReceived.type,
            payload: null,
        });
    };

    const ackAction = async (
        actionName,
        cardId,
        targetAmount,
        selected,
        message
    ) => {
        if (!reactionState.current) {
            reactionState.current = {
                play_as: "REPLY",
                actionCard: cardId,
                actionName,
                targetAmount,
                step: 1,
            };
        } else {
            reactionState.current.step += 1;
        }

        const AJNCards = getAJNCards(privateData);

        switch (reactionState.current.actionName) {
            case "SLY_DEAL":
            case "FORCED_DEAL":
            case "DEAL_BREAKER":
                switch (reactionState.current.step) {
                    case 1:
                        // Ask user to either play just say no or ack
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: false,
                            payload: {
                                open: true,
                                cardCode:
                                    allCards[reactionState.current.actionCard]
                                        .code,
                                body: message,
                                options: AJNCards.length
                                    ? [
                                          {
                                              id: "JSN",
                                              name: "Just Say No",
                                              requestCallback: true,
                                          },
                                          {
                                              id: "ACK",
                                              name: "Acknowledge",
                                              requestCallback: true,
                                          },
                                      ]
                                    : [
                                          {
                                              id: "ACK",
                                              name: "Acknowledge",
                                              requestCallback: true,
                                          },
                                      ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "JSN":
                                reactionState.current = {
                                    ...reactionState.current,
                                    card_id: AJNCards[0],
                                };
                                break;
                            case "ACK":
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        await _ackAction();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong!",
                            reactionState.current
                        );
                        reactionState.current = null;
                }
                break;
            case "DEBT_COLLECTOR":
            case "ITS_MY_BIRTHDAY":
            case "RENT":
                // Skip to step 2 if the user has no JSN card
                if (!AJNCards.length && reactionState.current.step === 1) {
                    reactionState.current.step += 1;
                    selected = "PAY";
                }
                switch (reactionState.current.step) {
                    case 1:
                        // Ask user to either play just say no or ack
                        await dispatch({
                            type: dialogUpdated.type,
                            payload: {
                                open: true,
                                collapsable: false,
                                cardCode: null,
                                body: message,
                                options: [
                                    {
                                        id: "JSN",
                                        name: "Just Say No",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "PAY",
                                        name: "Pay",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "JSN":
                                reactionState.current = {
                                    ...reactionState.current,
                                    card_id: AJNCards[0],
                                };
                                await _ackAction();
                                break;
                            case "PAY":
                                await dispatch({
                                    type: cardPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: false,
                                        title: message,
                                        body: "Select cards to pay.",
                                        targetAmount:
                                            reactionState.current.targetAmount,
                                        options: [
                                            {
                                                id: "PAY",
                                                name: "PAY",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        break;
                    case 3:
                        switch (selected) {
                            case "PAY":
                                reactionState.current.payload = {
                                    card_ids: selectedCards,
                                };
                                await _ackAction();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong!",
                            reactionState.current
                        );
                        reactionState.current = null;
                }
                break;
            default:
                if (cardId) {
                    await dispatch({
                        type: dialogUpdated.type,
                        payload: {
                            open: true,
                            cardCode: allCards[cardId].code,
                            body: message,
                        },
                    });
                }
                console.log("Unrecognised reaction!", reactionState.current);
                reactionState.current = null;
        }
    };

    const startGame = async (roomId, callback) => {
        socketRef.current.emit(
            "start_game",
            {
                room_id: roomId,
            },
            (res) => {
                console.log(res);
                callback(res);
            }
        );
    };

    const terminateAction = () => {
        actionState.current = null;
        console.log("Action state cleared: ", actionState.current);
    };

    const skipTurn = () => {
        socketRef.current.emit(
            "action",
            {
                skip_turn: true,
                play_as: "ACTION",
            },
            (res) => {
                console.log(res);
                toast(res.message);
            }
        );
    };

    const getCashDrawer = () => {
        return {
            playerId: privateData.player_id,
            propertySets: Object.values(
                game.players[privateData.player_id].public.sets
            ),
            bankCards: Object.values(privateData.private.sets).filter((set) =>
                set.id.startsWith("bk")
            )[0].cards,
            bankId: Object.values(privateData.private.sets).filter((set) =>
                set.id.startsWith("bk")
            )[0].id,
        };
    };

    useEffect(() => {
        if (!socketRef.current) {
            const authToken = localStorage.getItem("auth");
            socketRef.current = io("https://api.dealmonopoly.com", {
                path: "/socket/game-service",
                extraHeaders: {
                    Authorization: authToken,
                },
            });

            socketRef.current.on("gamedata", (response) => {
                console.log("Game data listener received: ", response);
                if (!response) return;
                // If the response has player_id field, it is private data
                if (response.player_id) {
                    dispatch({
                        type: privateDataFetched.type,
                        payload: response,
                    });
                } else
                    dispatch({
                        type: publicDataFetched.type,
                        payload: response,
                    });
                if (!localStorage.getItem("gameStarted")) {
                    localStorage.setItem("gameStarted", true);
                    window.location.reload();
                }
            });

            socketRef.current.on("metadata", (response) => {
                console.log("Meta data listener received: ", response);
                dispatch({
                    type: cardsFetched.type,
                    payload: response,
                });
            });

            socketRef.current.on("notification", (response) => {
                console.log("Notification listener received: ", response);
                toast(response.message);
                socketRef.current.emit("notification", {
                    notification_id: response.id,
                });
            });

            socketRef.current.on("action", (response) => {
                console.log("Action listener received:", response);
                dispatch({
                    type: actionReceived.type,
                    payload: response,
                });
            });

            socketRef.current.on("players", async (response) => {
                await dispatch({
                    type: peersFetched.type,
                    payload: response,
                });
                console.log("Players: ", response);
            });

            socketRef.current.on("connect", () => {
                console.log(`Connected: ${socketRef.current.id}`);
            });

            socketRef.current.on("connect_error", (reason) => {
                console.log(`Connection error: ${reason}`);
                // Maybe the token has expired, reset and retry
                if (localStorage.getItem("auth")) {
                    // TODO: if error message shows that its an expired token, clear else retry
                    // TODO: below goes in if part
                    localStorage.removeItem("auth");
                    localStorage.removeItem("gameStarted");
                    window.location.reload();
                    // TODO: below goes in else part
                    // setTimeout(() => {
                    //   socketRef.current.connect();
                    // }, 1000);
                }
            });

            socketRef.current.on("disconnect", (reason) => {
                console.log(`Disconnected: ${reason}`);
            });

            // socketRef.current.onAny((eventName, ...args) => {
            //   console.log(eventName, args);
            // });
        } else if (
            allCards &&
            game &&
            privateData &&
            action &&
            !action.is_fulfilled &&
            game.turn.active_action
        ) {
            // Ack action only if this user is liable
            if (
                game.turn.active_action.liable_players &&
                game.turn.active_action.liable_players.filter(
                    (player) => player.player_id === privateData.player_id
                )[0]
            ) {
                ackAction(
                    action.action_name,
                    action.action_card,
                    action.value,
                    null,
                    action.message
                );
            }
        }
    }, [allCards, game, privateData, action, dispatch]);

    return (
        <WebSocketContext.Provider
            value={{
                playCard,
                terminateAction,
                ackAction,
                skipTurn,
                startGame,
                getCashDrawer,
            }}
        >
            {props.children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;
