import React from "react";
import { Fragment, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import User from "../../components/User/User.js";
import Info from "../Info/Info";
import { ABSENT, PRESENT, EXPIRED } from "../../constants/status.js";
import { loadingShown, loadingHidden } from "../../store/ui.js";
import { fetchRoomDetails } from "../../store/room.js";
import { fetchUserDetails } from "../../store/user.js";
import { leaveRoom } from "../../store/room.js";
import { WebSocketContext } from "../../WebSocket.js";

import "./UsersList.css";

const UsersList = () => {
    const room = useSelector((state) => state.entities.room);
    const users = useSelector((state) => state.entities.peers);
    const user = useSelector((state) => state.entities.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ws = useContext(WebSocketContext);

    const leaveRoomHandler = () => {
        dispatch(leaveRoom());
        navigate(0);
    };

    if (room.status === EXPIRED) leaveRoomHandler();

    if (room.status === ABSENT) {
        dispatch(loadingShown());
        dispatch(fetchRoomDetails());
        dispatch(fetchUserDetails());
    }

    const startGame = () => {
        ws.startGame(room.room_id, async (response) => {
            console.log(response.is_success);
            if (response.is_success) {
                await localStorage.setItem("gameStarted", true);
                // window.location.reload();
                navigate(0);
            } else toast(response.message);
        });
    };

    useEffect(() => {
        dispatch(loadingHidden());
    }, [dispatch, room]);

    if (room.status === PRESENT) {
        return (
            <div className="auth-form on">
                <ToastContainer />
                <div className="top-logo-container">
                    <img src="/logo.png" alt="logo" />
                </div>
                <div className="form-title">{room.room_name}</div>
                <Info room={room} />
                <div className="chatbox-user-list">
                    {Object.values(users).map((user, index) => (
                        <User key={index} user={user} />
                    ))}
                </div>
                {user.is_host ? (
                    <button
                        className="form-button"
                        type="Submit"
                        onClick={startGame}
                    >
                        Start
                    </button>
                ) : null}
                <p>
                    <span onClick={leaveRoomHandler}>Leave room.</span>
                </p>
            </div>
        );
    } else {
        return <Fragment></Fragment>;
    }
};

export default UsersList;
