const hasClass = (ele, cls) => {
    return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
};

// const addClass = (ele, cls) => {
//   if (!hasClass(ele, cls)) ele.className += " " + cls;
// };

// const removeClass = (ele, cls) => {
//   if (hasClass(ele, cls)) {
//     var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
//     ele.className = ele.className.replace(reg, " ");
//   }
// };

export const handleCircularStack = (event) => {
    let _zIndex = 20;
    let incr = 1;
    const target = event.target;
    const container = target.parentElement.parentElement;
    Array.prototype.forEach.call(container.children, (ele) => {
        let child = ele.children[0];
        ele.style.zIndex = _zIndex;
        // child.style.transform = "";
        if (child === target) incr = -1;
        _zIndex += incr;
    });
    // target.style.transform = "scale(1.2)";
};

var zIndex = 1;

const setupClickHandlers = () => {
    document
        .querySelectorAll(".child-horizontal-stack")
        .forEach((ele) =>
            ele.addEventListener("mouseover", handleCircularStack)
        );

    const boxes = document.getElementsByClassName("stacks-container");
    Array.prototype.forEach.call(boxes, (box) => {
        if (!hasClass(box, "special-container")) {
            box.addEventListener("mousedown", () => {
                zIndex = zIndex + 1;
                box.style.zIndex = zIndex;
            });
        }
    });
};

export const setUpCardPickerListeners = () => {
    document
        .querySelectorAll(".child-horizontal-stack")
        .forEach((ele) =>
            ele.addEventListener("mouseover", handleCircularStack)
        );
};

export default setupClickHandlers;
