import React from "react";

import "./User.css";

const User = ({ user }) => (
    <div className={"user-detail" /*+ (user.online ? "" : " away")*/}>
        <div className="user-avatar">
            <img src={"/avatars/" + user.avatar} alt={user.avatar} />
        </div>
        <div className="user-name dont-break-out">
            <span>
                {user.name}
                {user.is_host ? " (Host)" : ""}
            </span>
        </div>
    </div>
);

export default User;
