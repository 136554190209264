import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "auth",
    initialState: {
        token: localStorage.getItem("auth"),
    },
    reducers: {
        // action => action handler
        authenticated: (auth, action) => {
            auth.token = action.payload.data.token;
            localStorage.setItem("auth", auth.token);
        },
        removed: (auth, action) => {
            delete auth.token;
            localStorage.removeItem("auth");
        },
        detailsFetched: (auth, action) => {},
    },
});

export const { authenticated, removed, detailsFetched } = slice.actions;

export default slice.reducer;
