import Draggable from "react-draggable";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { WebSocketContext } from "../../WebSocket.js";
import TitleBar from "../TitleBar/TitleBar.js";
import Stack from "../Stack/Stack.js";
import { setWindowExpanded, setWindowMinimised } from "../../store/ui.js";

import "../GameContainer/GameContainer.css";
import "../Window/Window.css";

const Window = ({
    winnerId,
    turn,
    viewerId,
    player: {
        playerId,
        playerName,
        propertySets,
        bankCards,
        handCards,
        bankId,
        handId,
    },
}) => {
    const ws = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const expanded = useSelector((state) => state.ui.windowExpanded);
    const minimised = useSelector((state) => state.ui.windowMinimised);
    const isTurn = playerId === turn.turn;
    const isViewer = playerId === viewerId;

    const handleToggleExpand = (event) => {
        event.preventDefault();
        dispatch(setWindowExpanded({ windowId: playerId }));
    };

    const handleToggleMinimise = (event) => {
        event.preventDefault();
        dispatch(setWindowMinimised({ windowId: playerId }));
    };

    const skipTurn = () => {
        ws.skipTurn();
    };

    const turn_left = 4 - turn.turn_number - 1;
    const html = (
        <div
            className={
                "window" +
                (isViewer ? " special-container" : "") +
                (expanded[playerId] ? " maximised-window" : "")
            }
        >
            <TitleBar
                isWinner={winnerId === playerId}
                title={playerName}
                numNotifs={isTurn ? turn_left : null}
                ctrlBtns={[
                    {
                        label: turn_left > 0 ? "Pass" : "End Turn",
                        clickHandler: skipTurn,
                        visible: isTurn && isViewer,
                    },
                    {
                        label: expanded[playerId] ? "x" : "+",
                        clickHandler: handleToggleExpand,
                        visible: true,
                    },
                    {
                        label: minimised[playerId] ? "^" : "-",
                        clickHandler: handleToggleMinimise,
                        visible: true,
                    },
                ]}
                isTurn={isTurn}
            />
            <div
                className={
                    "stacks-container" +
                    (minimised[playerId] ? " stacks-hidden" : "")
                }
            >
                {
                    /* Displaying bank cards of the player */
                    bankCards && bankCards.length ? (
                        <Stack
                            setId={bankId}
                            playerId={playerId}
                            type={"vertical"}
                            cards={bankCards}
                            cash={!isViewer}
                            expanded={
                                expanded[playerId] ? expanded[playerId] : false
                            }
                        />
                    ) : null
                }
                {
                    /* Displaying property sets of the player */
                    propertySets && propertySets.length
                        ? propertySets.map((set) =>
                              set.id ? (
                                  <Stack
                                      key={set.id}
                                      setId={set.id}
                                      playerId={playerId}
                                      type={"vertical"}
                                      cards={set.cards}
                                      expanded={
                                          expanded[playerId]
                                              ? expanded[playerId]
                                              : false
                                      }
                                  />
                              ) : null
                          )
                        : null
                }
                {
                    /* Displaying hand cards of the player */
                    handCards && handCards.length ? (
                        <Stack
                            setId={handId}
                            playerId={playerId}
                            type={"horizontal"}
                            cards={handCards}
                            expanded={
                                expanded[playerId] ? expanded[playerId] : false
                            }
                        />
                    ) : null
                }
            </div>
        </div>
    );

    if (isViewer) return html;
    else
        return (
            <Draggable handle=".drag" grid={[40, 40]} bounds={"parent"}>
                {html}
            </Draggable>
        );
};

export default Window;
