import { useSelector } from "react-redux";
import uuid from "react-uuid";

import Card from "../Card/Card.js";

import "../GameContainer/GameContainer.css";
import "../Stack/Stack.css";

const Stack = ({
    type,
    setId,
    playerId,
    cards,
    cash,
    expanded,
    onCardClick,
    selected,
}) => {
    const cardDetails = useSelector((state) => state.entities.cards.all);
    const selectedCards = useSelector((state) => state.entities.cards.selected);

    return (
        <div
            className={
                "parent-" +
                type +
                "-stack" +
                (cash ? " parent-cash-stack" : "") +
                (expanded
                    ? " enlarged-parent-" +
                      type +
                      "-stack" +
                      (cash ? " enlarged-cash-stack" : "")
                    : "") +
                (selected ? " outlined-stack" : "")
            }
        >
            {cards.map((cardId) => (
                <Card
                    type={type}
                    cardId={cardId}
                    code={
                        cardDetails && cardDetails[cardId]
                            ? cardDetails[cardId].code
                            : ""
                    }
                    setId={setId}
                    playerId={playerId}
                    cash={cash}
                    key={cardId ? cardId : uuid()}
                    expanded={expanded}
                    onCardClick={onCardClick}
                    selected={selectedCards.includes(cardId) ? true : false}
                />
            ))}
        </div>
    );
};

export default Stack;
