import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../InputField/InputField.js";
import CheckBox from "../CheckBox/CheckBox.js";
import { createRoom, joinRoom } from "../../store/room";
import { loadingShown, loadingHidden } from "../../store/ui";

// import full_logo from "../../icons/logos/logo_full_size.png";

import "./AuthForm.css";

const AuthForm = (props) => {
    // const onChangeStaticLink = () => props.setStaticLink(!props.staticLink);
    // const onChangeUniqueLink = () => props.setUniqueLink(!props.uniqueLink);
    // const onChangePasscodeEnabled = () => props.setPasscodeEnabled(!props.passcodeEnabled);

    const validateName = () => {
        const err = props.name.length ? "" : "Please enter username.";
        props.setNameErr(err);
        return err;
    };

    const validateRoom = () => {
        const err = props.room.length ? "" : "Please enter room name.";
        props.setRoomErr(err);
        return err;
    };

    const validateRoomID = () => {
        const err = props.roomID.length ? "" : "Please enter room ID.";
        props.setRoomIDErr(err);
        return err;
    };

    const authInputFieldProps = {
        Join: [
            {
                name: "RoomID",
                type: "text",
                placeholder: "Room ID",
                required: "required",
                value: props.roomID,
                error: props.roomIDErr,
                onChange: props.setRoomID,
                onBlur: validateRoomID,
            },
            {
                name: "Username",
                type: "text",
                placeholder: "Your Name",
                required: "required",
                value: props.name,
                error: props.nameErr,
                onChange: props.setName,
                onBlur: validateName,
            },
            // {
            //     name: "Password",
            //     type: "password",
            //     placeholder: "Passcode"
            // },
        ],
        Host: [
            {
                name: "RoomName",
                type: "text",
                placeholder: "Rooom Name",
                required: "required",
                value: props.room,
                error: props.roomErr,
                onChange: props.setRoom,
                onBlur: validateRoom,
            },
            {
                name: "Username",
                type: "text",
                placeholder: "Your Name",
                required: "required",
                value: props.name,
                error: props.nameErr,
                onChange: props.setName,
                onBlur: validateName,
            },
        ],
    };

    const authCheckBoxProps = {
        Join: [],
        Host: [
            // {
            //     description: "Static link",
            //     name: "static_link",
            //     checked: props.staticLink,
            //     onChange: onChangeStaticLink
            // },
            // {
            //     description: "Passcode enabled",
            //     name: "passcode_enabled",
            //     checked: props.passcodeEnabled,
            //     onChange: onChangePasscodeEnabled
            // },
            // {
            //     description: "Unique link for everyone",
            //     name: "unqiue_links",
            //     checked: props.uniqueLink,
            //     onChange: onChangeUniqueLink
            // }
        ],
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRoomJoin = async (event) => {
        console.log("Joining room!");
        event.preventDefault();
        const isInputInvalid = await (validateName().length +
            validateRoomID().length);
        if (isInputInvalid) return toast("Invalid input!");
        // Clean stale logout phase flag
        // await dispatch(setIsLogoutPhase(false));
        await dispatch(loadingShown());
        await dispatch(
            joinRoom({
                room: props.roomID,
                username: props.name,
                avatar: props.avatar,
                datetime: Date.now(),
            })
        );
        dispatch(loadingHidden());
        console.log("Room joined!");
        //window.location.reload();
        navigate(0);
    };

    const handleRoomHost = async (event) => {
        console.log("Hosting room!");
        event.preventDefault();
        const isInputInvalid = await (validateName().length +
            validateRoom().length);
        if (isInputInvalid) return toast("Invalid input!");
        await dispatch(loadingShown());
        await dispatch(
            createRoom({
                room_name: props.room,
                host_name: props.name,
                avatar: props.avatar,
                max_num_users: 5,
                unique_link: props.uniqueLink,
                passcode_enabled: props.passcodeEnabled,
                static_link: props.staticLink,
                datetime: Date.now(),
            })
        );
        dispatch(loadingHidden());
        console.log("Room hosted!");
        //window.location.reload();
        navigate(0);
    };

    return (
        <div className={props.formClass}>
            <div className={"top-logo-container"}>
                <img src={"/images/logo.png"} alt={"logo"} />
            </div>
            <div className="form-title">{props.authType}</div>
            <form>
                {authInputFieldProps[props.authType].map(
                    (inputFieldProp, index) => (
                        <InputField key={index} props={inputFieldProp} />
                    )
                )}
                <label>Choose an avatar:</label>
                <div className="avatars-picker">
                    {props.avatars.map((avatarName, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    avatarName === props.avatar
                                        ? "avatar-skeleton-selected"
                                        : "avatar-skeleton"
                                }
                            >
                                <img
                                    src={"/avatars/" + avatarName}
                                    className="avatar-icon"
                                    alt={avatarName}
                                    onClick={(event) =>
                                        props.setAvatar(avatarName)
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
                {authCheckBoxProps[props.authType].map(
                    (checkBoxProp, index) => (
                        <CheckBox key={index} props={checkBoxProp} />
                    )
                )}
                <button
                    className="form-button"
                    type="Submit"
                    onClick={(event) =>
                        props.authType === "Join"
                            ? handleRoomJoin(event)
                            : handleRoomHost(event)
                    }
                >
                    {props.authType}
                </button>
                <p>
                    {props.authType === "Join" ? "Don't" : "Already"} have a
                    room?
                    <span onClick={props.switchPanel}>
                        {props.authType === "Join" ? "Host one." : "Join here."}
                    </span>
                </p>
            </form>
        </div>
    );
};

export default AuthForm;
