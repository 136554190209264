import React from "react";
import { ToastContainer } from "react-toastify";

import "./Container.css";

const Container = ({ content }) => {
    return (
        <div className="outer-container">
            <ToastContainer newestOnTop />
            <div className="inner-container">{content}</div>
        </div>
    );
};

export default Container;
