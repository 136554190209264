import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "../Container/Container";
import InterfacePanel from "../InterfacePanel/InterfacePanel.js";
import ImagePanel from "../ImagePanel/ImagePanel.js";
import Loading from "../Loading/Loading";

import "./HomeContainer.css";

const HomeContainer = () => {
    const { roomId } = useParams();
    const loading = useSelector((state) => state.ui.loading);

    const HomeComponent = (
        <Fragment>
            <InterfacePanel roomHandle={roomId ? roomId : ""} />
            <ImagePanel />
        </Fragment>
    );

    return <Container content={loading ? Loading : HomeComponent} />;
};

export default HomeContainer;
