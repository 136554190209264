import React, { useState } from "react";
import { Fragment } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { importAll } from "../../utils/images";
import AuthForm from "../AuthForm/AuthForm.js";
import UsersList from "../UsersList/UsersList.js";

import "./InterfacePanel.css";

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const avatars = importAll(
    require.context("../../../public/avatars", false, /\.(png|jpe?g|svg)$/)
);

const InterfacePanel = ({ roomHandle }) => {
    const [classJoin, setClassJoin] = useState("auth-form on");
    const [classHost, setClassHost] = useState("auth-form");
    const [hiddenPanel, setHiddenPanel] = useState("Host");

    const switchPanel = (event) => {
        const temp = classHost;
        setClassHost(classJoin);
        setClassJoin(temp);
        hiddenPanel === "Host"
            ? setHiddenPanel("Join")
            : setHiddenPanel("Host");
    };

    const [name, setName] = useState("");
    const [nameErr, setNameErr] = useState("");
    const [room, setRoom] = useState("");
    const [roomErr, setRoomErr] = useState("");
    const [roomID, setRoomID] = useState(roomHandle || "");
    const [roomIDErr, setRoomIDErr] = useState("");
    const [avatarNames] = useState(shuffle(Object.keys(avatars)));
    const [avatar, setAvatar] = useState(avatarNames[0]);

    const [staticLink, setStaticLink] = useState(true);
    const [passcodeEnabled, setPasscodeEnabled] = useState(true);
    const [uniqueLink, setUniqueLink] = useState(true);

    const authed = localStorage.getItem("auth");

    return (
        <div className="panel">
            {authed ? (
                <UsersList />
            ) : (
                <Fragment>
                    <div className="auth-switch" onClick={switchPanel}>
                        {hiddenPanel}
                    </div>
                    <AuthForm
                        formClass={classJoin}
                        authType={"Join"}
                        switchPanel={switchPanel}
                        avatars={avatarNames}
                        name={name}
                        nameErr={nameErr}
                        roomID={roomID}
                        roomIDErr={roomIDErr}
                        avatar={avatar}
                        staticLink={staticLink}
                        passcodeEnabled={passcodeEnabled}
                        uniqueLink={uniqueLink}
                        setName={setName}
                        setNameErr={setNameErr}
                        setRoomID={setRoomID}
                        setRoomIDErr={setRoomIDErr}
                        setAvatar={setAvatar}
                        setStaticLink={setStaticLink}
                        setPasscodeEnabled={setPasscodeEnabled}
                        setUniqueLink={setUniqueLink}
                        roomHandle={roomHandle}
                    />
                    <AuthForm
                        formClass={classHost}
                        authType={"Host"}
                        switchPanel={switchPanel}
                        avatars={avatarNames}
                        name={name}
                        nameErr={nameErr}
                        room={room}
                        roomErr={roomErr}
                        avatar={avatar}
                        setName={setName}
                        setNameErr={setNameErr}
                        setRoom={setRoom}
                        setRoomErr={setRoomErr}
                        setAvatar={setAvatar}
                    />
                </Fragment>
            )}
        </div>
    );
};

export default InterfacePanel;
