import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { default as BaseModal } from "@mui/material/Modal";
import ImageListItem from "@mui/material/ImageListItem";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function Modal({ open, image, title, texts }) {
    return (
        <BaseModal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ImageListItem>
                    <img src={image} alt={title} />
                </ImageListItem>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    align="center"
                >
                    {title}
                </Typography>
                {texts.map((text, index) => (
                    <Typography
                        key={index}
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                        align="center"
                    >
                        {text}
                    </Typography>
                ))}
            </Box>
        </BaseModal>
    );
}
