import { useState } from "react";
import "./Info.css";

const Info = ({ room }) => {
    const [tooltipText, setTooltipText] = useState("copy");

    return (
        <div className="room-info-outer-container">
            <div className="room-info-inner-container">
                <div>
                    <p>Share the link with others to join the room:</p>
                    {room.links &&
                        room.links.map((link, index) => (
                            <div
                                key={index}
                                className="click-to-copy-container tooltip"
                            >
                                <p>{link}</p>
                                <div className="tooltip">
                                    <img
                                        src={"/icons/copy.png"}
                                        alt="copy"
                                        onClick={() => {
                                            navigator.clipboard.writeText(link);
                                            setTooltipText("copied");
                                        }}
                                    />
                                    <span className="tooltiptext">
                                        {tooltipText}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Info;
