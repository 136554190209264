import React from "react";
import "./ImagePanel.css";

const ImagePanel = () => (
    <div className="panel">
        <div className="home-image-overlay"></div>
        <div className="home-welcome-text-container">
            Play Monopoly Deal
            <span className="home-disclaimer-message">
                This game is similar to monopoly deal game, but it in no way
                officially affiliated with Hasbro.
            </span>
        </div>
        <div className="home-image-side">
            <div className={"logo-container"}>
                <img src={"/images/wall.jpg"} alt={"logo"} />
            </div>
        </div>
    </div>
);

export default ImagePanel;
