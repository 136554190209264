import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiCallBegan } from "./api";

import { ABSENT, LOADING, LOGOUT, PRESENT } from "../constants/status";

const slice = createSlice({
    name: "user",
    initialState: {
        // status: ABSENT,
        status: PRESENT,
        name: "Shivam",
        avatar: "021-sloth-min",
    },
    reducers: {
        // action => action handler
        userDetailsFetched: (user, action) => {
            user = Object.assign(user, {
                status: PRESENT,
                ...action.payload.data,
            });
        },

        userDetailsRequested: (user, action) => {
            user.status = LOADING;
        },

        userDetailsFetchFailed: (user, action) => {
            if (action.payload.status === 403) user.status = LOGOUT;
            else user.status = ABSENT;
            toast(
                action.payload.message
                    ? action.payload.message
                    : "Some error occured!"
            );
        },

        userDetailsRemoved: (user, action) => {
            Object.keys(user).forEach((key) => delete user[key]);
            user.status = ABSENT;
        },
    },
});

export const fetchUserDetails = () => (dispatch, getState) => {
    dispatch({ type: userDetailsRequested.type });

    return dispatch(
        apiCallBegan({
            url: "/user/detail/",
            method: "GET",
            headers: { Authorization: getState().auth.token },
            onSuccess: userDetailsFetched.type,
            onError: userDetailsFetchFailed.type,
        })
    );
};

export const {
    userDetailsRequested,
    userDetailsFetched,
    userDetailsFetchFailed,
    userDetailsRemoved,
} = slice.actions;

export default slice.reducer;
