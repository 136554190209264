import React from "react";

import "./Loading.css";

const Loading = (
    <div className="loading-outer-container">
        <div className="loading-inner-container">
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);

export default Loading;
