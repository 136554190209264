import { combineReducers } from "redux";
import cardsReducer from "./cards";
import setsReducer from "./sets";
import gameReducer from "./game";
import roomReducer from "./room";
import peersReducer from "./peers";
import userReducer from "./user";

export default combineReducers({
    cards: cardsReducer,
    sets: setsReducer,
    game: gameReducer,
    room: roomReducer,
    peers: peersReducer,
    user: userReducer,
});
