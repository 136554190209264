import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import LogoutIcon from "@mui/icons-material/Logout";
// import HistoryIcon from "@mui/icons-material/History";
import ChatIcon from "@mui/icons-material/Chat";

import { leaveRoom } from "../../store/room.js";

import "./FloatingMenu.css";

const FloatingMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const leaveRoomHandler = () => {
        dispatch(leaveRoom());
        navigate(0);
    };

    const feedbackHandler = () => {
        window.open("https://forms.gle/seMoJZLSYP9sBbFb6");
    };

    return (
        <Box className="floating-menu" sx={{ "& > :not(style)": { m: 1 } }}>
            <Fab size="small" color="primary" aria-label="logout">
                <LogoutIcon onClick={leaveRoomHandler} />
            </Fab>
            {/* <Fab size="small" color="secondary" aria-label="history">
        <HistoryIcon />
      </Fab> */}
            <Fab size="small" color="success" aria-label="chat">
                <ChatIcon onClick={feedbackHandler} />
            </Fab>
        </Box>
    );
};

export default FloatingMenu;
