import React from "react";

import "./InputField.css";

const InputField = ({ props }) => (
    <div className="form-input-container">
        <input
            name={props.name}
            type={props.type}
            required={props.required}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(event) =>
                props.onChange ? props.onChange(event.target.value) : null
            }
            onBlur={(event) =>
                props.onBlur ? props.onBlur(event.target.value) : null
            }
        />
        <span>{props.error}</span>
    </div>
);

export default InputField;
