import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "sets",
    initialState: {
        selected: null,
    },
    reducers: {
        setsSelected: (sets, action) => {
            sets.selected = action.payload;
        },
    },
});

export const { setsSelected } = slice.actions;

export default slice.reducer;
